const lineageData = [
  {
    id: 0,
    name: 'Choose Lineage',
    strains: [],
    body: 0,
    mind: 0,
  },
  {
    id: 1,
    name: 'Elitariat',
    strains: [
      { id: 3, name: 'Digitarian' },
      { id: 9, name: 'Pure Blood' },
      { id: 20, name: 'Solestros' },
    ],
    variants: {
      civic: {
        advantage: 'Deeper Pockets',
        disadvantage: 'Limited Immune System',
      },
      wastelander: {
        advantage: 'Long Legacy',
        disadvantage: 'Longer Grudges',
      },
    },
    body: 5,
    mind: 15,
  },
  {
    id: 2,
    name: 'Townie',
    strains: [
      { id: 1, name: 'Baywalker' },
      { id: 16, name: 'Yorker' },
      { id: 15, name: 'Vegasian' },
    ],
    variants: {
      civic: {
        advantage: 'Built For This',
        disadvantage: 'Stupid Emotions',
      },
      wastelander: {
        advantage: 'Guard Up',
        disadvantage: 'Trust No One',
      },
    },
    body: 10,
    mind: 10,
  },
  {
    id: 3,
    name: 'Nomad',
    strains: [
      { id: 17, name: 'Diesel jock' },
      { id: 13, name: 'Rover' },
      { id: 19, name: 'Saltwise' },
    ],
    variants: {
      civic: {
        advantage: 'Iron Pact Debt',
        disadvantage: 'Old Debts',
      },
      wastelander: {
        advantage: 'Dangerous Routes',
        disadvantage: 'Out There Is My Home',
      },
    },
    body: 12,
    mind: 8,
  },
  {
    id: 4,
    name: 'Gorger',
    strains: [
      { id: 2, name: 'Full Dead' },
      { id: 14, name: 'Semper Mort' },
      { id: 5, name: 'Lascarian' },
    ],
    variants: {
      civic: {
        advantage: 'Leftovers',
        disadvantage: "Yeah... We Don't Do That",
      },
      wastelander: {
        advantage: 'Wastelands Hunger',
        disadvantage: 'No Point To Civility',
      },
    },
    body: 10,
    mind: 10,
  },
  {
    id: 5,
    name: 'Mutant',
    strains: [
      { id: 11, name: 'Remnant' },
      { id: 12, name: 'Retrograde' },
      { id: 22, name: 'Tainted' },
    ],
    variants: {
      civic: {
        advantage: 'Adaptive Consumption',
        disadvantage: 'Unique Digestion',
      },
      wastelander: {
        advantage: 'Cornered Animal',
        disadvantage: 'Not House Broken',
      },
    },
    body: 15,
    mind: 5,
  },
  {
    id: 6,
    name: 'Landsman',
    strains: [
      { id: 6, name: 'Merican' },
      { id: 8, name: 'Natural One' },
      { id: 23, name: 'Quiet Folk' },
    ],
    variants: {
      civic: {
        advantage: 'Did You Hear?',
        disadvantage: 'You Know I Did!',
      },
      wastelander: {
        advantage: 'Too Stubborn To Die',
        disadvantage: 'Too Surly To Live',
      },
    },
    body: 13,
    mind: 7,
  },
  {
    id: 7,
    name: 'Devoted',
    strains: [
      { id: 7, name: 'Accensorite' },
      { id: 10, name: 'Red Star' },
      { id: 21, name: 'Unborn' },
    ],
    variants: {
      civic: {
        advantage: 'Spirit, Not Letter, Of The Law',
        disadvantage: 'Dampened Fires',
      },
      wastelander: {
        advantage: 'Hard To Kill',
        disadvantage: 'No Middle Ground',
      },
    },
    body: 5,
    mind: 15,
  },
  {
    id: 8,
    name: 'Evolved',
    strains: [
      { id: 4, name: 'Iron' },
      { id: 18, name: 'Reclaimer' },
      { id: 24, name: 'Unstable' },
    ],
    variants: {
      civic: {
        advantage: 'Preparations For War',
        disadvantage: 'Deserved Distrust',
      },
      wastelander: {
        advantage: "I'll Do It Myself",
        disadvantage: 'Self Care',
      },
    },
    body: 10,
    mind: 10,
  },
];

export default lineageData;
