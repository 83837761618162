import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import BaseSelect from 'src/components/0300_base_select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesDown, faAnglesUp } from '@fortawesome/free-solid-svg-icons';
import useLineageVariant from '../../hooks/useLineageVariant';

interface IProps {
  canEdit: boolean;
}

const LineageVariant: FC<IProps> = ({ canEdit }) => {
  const { advantage, disadvantage } = useLineageVariant();
  const { register, watch } = useFormContext();
  const { variant } = watch();

  return (
    <div className="grid gap-2">
      <BaseSelect
        customSort
        isLocked={!canEdit}
        selectedValue={variant}
        width="w-full"
        options={[
          { value: 'standard', label: 'Variant: Standard' },
          { value: 'civic', label: 'Variant: Civic' },
          { value: 'wastelander', label: 'Variant: Wastelander' },
        ]}
        {...register( 'variant' )}
      />
      {advantage && (
        <div className="flex gap-2 items-center pl-2">
          <FontAwesomeIcon
            icon={faAnglesUp}
            className="text-juno-cyan-400 brightness-125"
          />
          {advantage}
        </div>
      )}
      {disadvantage && (
        <div className="flex gap-2 items-center pl-2">
          <FontAwesomeIcon
            icon={faAnglesDown}
            className="text-juno-orange-400 brightness-125"
          />
          {disadvantage}
        </div>
      )}
    </div>
  );
};

export default LineageVariant;
