import { FC } from 'react';
import { useParams } from 'react-router-dom';
import LoupeView from 'src/components/0500_depository/dystopia_rising/LoupeView';
import usePermission from 'src/hooks/permissions/usePermissions';

const EventDepository: FC = () => {
  const { eventId } = useParams();
  const { isPermitted: canAssistLogistics } = usePermission({
    action: 'assists_logistics',
    eventId: Number( eventId ),
  });

  if ( !canAssistLogistics ) return null;

  return (
    <LoupeView components={{ withItemCreation: false, withTitle: true }} />
  );
};

export default EventDepository;
