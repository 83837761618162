import { FC } from 'react';
import LoupeView, {
  TLoupeViewProps,
} from 'src/components/0500_depository/dystopia_rising/LoupeView';
import useOrganization from 'src/hooks/organizations/useOrganization';
import usePermission from 'src/hooks/permissions/usePermissions';

interface IProps extends TLoupeViewProps {}

const Depository: FC<IProps> = props => {
  const { organization } = useOrganization();
  const { isPermitted: canUseDepository } = usePermission({
    action: 'create_item',
  });

  if ( !canUseDepository ) return null;

  switch ( organization?.slug ) {
    case 'dystopia-rising':
      return <LoupeView {...props} />;
    default:
      return null;
  }
};

export default Depository;
